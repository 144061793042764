



























































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import InfoBox from '@/components/hszg/InfoBox.vue'
import { DELETE_EVENT_SEARCH_AGENT, GET_ALL_EVENT_SEARCH_AGENTS } from '@/store/search/actions'
import { Agents, EventSearchAgentType } from '@/store/search/types'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import { KeyValue, SortingInfo } from '@/store/commonFormInput/types'
import ConfirmationModal from '@/components/common/ConfirmationModal.vue'
import { SimpleServerResponse } from '@/store/types'
import EventSearchAgent from '@/components/forms/EventSearchAgent.vue'
import { getCompetenceObjectArray, getTopicObjectArray } from '@/common/globals'
import { GET_ALL_TOPICS } from '@/store/event/actions.type'
import { GET_ALL_COMPETENCES } from '@/store/kode/actions'

@Component({
  components: { ResultsPage, EventSearchAgent, InfoBox, ConfirmationModal }
})
export default class EventSearchAgentPage extends Vue {
  $refs!: {
    editModal: EventSearchAgent;
  }

  @Action(GET_ALL_EVENT_SEARCH_AGENTS)
  public getAllEventSearchAgents: () => Promise<Agents>

  @Action(DELETE_EVENT_SEARCH_AGENT)
  public deleteEventSearchAgent: (id: string) => Promise<SimpleServerResponse>

  @Action(GET_ALL_TOPICS)
  public getAllTopics: () => Promise<Array<string>>

  @Action(GET_ALL_COMPETENCES)
  public getAllCompetences: () => Promise<Array<string>>

  private loading = false
  private searchAgents: Array<EventSearchAgentType> = []
  private selectedAgent: EventSearchAgentType = {
    searchAgentId: '',
    interval: '',
    startDate: new Date(),
    endDate: null,
    searchName: '',
    eventCompetencesDTO: {
      baseCompetences: [],
      competences: [],
      topics: []
    }
  }

  private competenceOptions: KeyValue[] = []
  private topicOptions: KeyValue[] = []
  private maxCount: number | null = null
  private modalTitle = ''

  get sortable (): SortingInfo[] {
    return [
      { key: 'startDate', label: this.$t('search-agent.start-date').toString() },
      { key: 'searchName', label: this.$t('search-agent.name').toString() }
    ]
  }

  showConfirmationModal (agent: EventSearchAgentType): void {
    this.selectedAgent = agent
    this.$bvModal.show('confirmation-modal-delete-search-agent')
  }

  clearModel (): void {
    this.selectedAgent = {
      searchAgentId: '',
      interval: '',
      startDate: new Date(),
      endDate: null,
      searchName: '',
      eventCompetencesDTO: {
        baseCompetences: [],
        competences: [],
        topics: []
      }
    }
    this.modalTitle = this.$t('search-agent.create-search-agent').toString()
  }

  prepareForEdit (agent: EventSearchAgentType) {
    this.selectedAgent = {
      searchAgentId: agent.searchAgentId,
      searchName: agent.searchName,
      startDate: agent.startDate,
      endDate: agent.endDate,
      interval: agent.interval,
      eventCompetencesDTO: {
        topics: agent.eventCompetencesDTO.topics,
        baseCompetences: agent.eventCompetencesDTO.baseCompetences,
        competences: agent.eventCompetencesDTO.competences
      }
    }
    this.modalTitle = this.$t('search-agent.edit-search-agent').toString()
    this.$bvModal.show('new-agent-modal')
  }

  updateAgentList (agent: EventSearchAgentType, isDelete: boolean): void {
    const index = this.searchAgents.findIndex(item => {
      return item.searchAgentId === agent.searchAgentId
    })

    if (isDelete && index !== -1) {
      this.searchAgents.splice(index, 1)
    }

    if (!isDelete) {
      if (index === -1) {
        this.searchAgents.splice(0, 0, agent)
      } else {
        this.$set(this.searchAgents, index, { ...agent })
      }
    }

    this.$bvModal.hide('new-agent-modal')
  }

  confirmDeleteModal (): void {
    let failed = true
    let message = ''
    this.deleteEventSearchAgent(this.selectedAgent.searchAgentId).then(() => {
      message = this.$t('info.delete-success', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = false
      this.updateAgentList(this.selectedAgent, true)
    }).catch(() => {
      message = this.$t('info.delete-failed', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = true
    }).finally(() => {
      this.$root.$emit('alert', message, '', failed)
      this.clearModel()
    })
  }

  saveAgent (): void {
    this.$refs.editModal.$refs.searchAgent.$refs.submitButton.click()
  }

  created (): void {
    this.clearModel()
    Promise.all([
      this.getAllCompetences(),
      this.getAllTopics()
    ]).then(data => {
      this.competenceOptions = getCompetenceObjectArray(data[0])
      this.topicOptions = getTopicObjectArray(data[1])
      this.loadAgents()
    }).catch(() => {
      this.$root.$emit('alert',
        this.$t('warning.error!').toString(),
        this.$tc('warning.could-not-load', 1, { what: this.$t('search-agent.event-search-agent') }).toString(),
        true
      )
    }).finally(() => {
      this.loading = false
    })
  }

  loadAgents (): void {
    this.loading = true
    this.getAllEventSearchAgents().then(data => {
      this.searchAgents = data.searchAgentList as EventSearchAgentType[]
      this.maxCount = data.maxNumberEventAgents
    }).catch(() => {
      this.$root.$emit('alert',
        this.$t('warning.error!').toString(),
        this.$tc('warning.could-not-load', 1, { what: this.$t('search-agent.event-search-agent') }).toString(),
        true
      )
    }).finally(() => {
      this.loading = false
    })
  }
}
