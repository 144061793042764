




















































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { EventSearchAgentType, JobSearchAgentType } from '@/store/search/types'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import SearchAgent from '@/components/forms/SearchAgent.vue'
import {
  getAllBaseCompetencesAsKeyValue,
  getBaseCompetenceObjectArray,
  getCompetenceObjectArray, getCompetenceValueAsStringArray,
  getTopicObjectArray
} from '@/common/globals'
import { Action } from 'vuex-class'
import { SAVE_EVENT_SEARCH_AGENT } from '@/store/search/actions'

@Component({
  components: { SearchAgent, BaseForm }
})
/*
This component is used on the EventSearchAgent page and uses the general SearchAgent component inside.
Most props are propagated to the SearchAgent component.
@group SEARCH AGENT
 */
export default class EventSearchAgent extends Vue {
  $refs!: {
    searchAgent: SearchAgent;
  }

  // unique identifier
  @Prop()
  id: string

  // the search data for the event search agent
  @Prop({ required: false })
  searchData: EventSearchAgentType

  // the competences (for the options of the competences multiselect)
  @Prop({ required: true })
  competenceOptions: KeyValue[]

  // the topics (for the options of the topics multiselect)
  @Prop({ required: true })
  topicOptions: KeyValue[]

  // if a delete button is shown
  @Prop({ default: false })
  showDelete: boolean

  // set true for use in a modal
  @Prop({ required: true })
  useInModal: boolean

  // edit mode for the search agent
  @Prop({ default: false })
  editMode: boolean

  @Watch('searchData', { immediate: true })
  onPropsChange (newProps: EventSearchAgentType): void {
    if (newProps) {
      this.internalModel = newProps
      if (newProps.eventCompetencesDTO && newProps.eventCompetencesDTO.competences && typeof newProps.eventCompetencesDTO.competences[0] === 'string') {
        this.internalModel.eventCompetencesDTO.competences = getCompetenceObjectArray(newProps.eventCompetencesDTO.competences as string[])
      }
      if (newProps.eventCompetencesDTO && newProps.eventCompetencesDTO.baseCompetences && typeof newProps.eventCompetencesDTO.baseCompetences[0] === 'string') {
        this.internalModel.eventCompetencesDTO.baseCompetences = getBaseCompetenceObjectArray(newProps.eventCompetencesDTO.baseCompetences as string[])
      }
      if (newProps.eventCompetencesDTO && newProps.eventCompetencesDTO.topics && typeof newProps.eventCompetencesDTO.topics[0] === 'string') {
        this.internalModel.eventCompetencesDTO.topics = getTopicObjectArray(newProps.eventCompetencesDTO.topics as string [])
      }
    }
  }

  @Action(SAVE_EVENT_SEARCH_AGENT)
  public saveEventAgent: (agent: EventSearchAgentType) => Promise<string>

  get fields (): Form[] {
    return [
      {
        id: 'baseCompetences',
        key: 'baseCompetences',
        type: InputTypes.MULTISELECT,
        required: false,
        label: this.$t('events.base-competence').toString(),
        options: getAllBaseCompetencesAsKeyValue(),
        autofocus: true,
        md: 12
      },
      {
        id: 'competences',
        key: 'competences',
        type: InputTypes.MULTISELECT,
        required: false,
        label: this.$t('events.competence').toString(),
        options: this.competenceOptions,
        md: 12
      },
      {
        id: 'topics',
        key: 'topics',
        type: InputTypes.MULTISELECT,
        required: false,
        label: this.$t('events.topic').toString(),
        options: this.topicOptions,
        md: 12
      }
    ]
  }

  private keyCounter = 0
  private isEditModeInternalModel = this.editMode

  get key (): string {
    return this.id + this.keyCounter
  }

  private internalModel: EventSearchAgentType = {
    searchAgentId: '',
    interval: '',
    startDate: new Date(),
    endDate: null,
    searchName: '',
    eventCompetencesDTO: {
      baseCompetences: [],
      competences: [],
      topics: []
    }
  }

  saveAgent (): void {
    let failed = true
    let message = ''
    const saveModel = {
      searchAgentId: this.internalModel.searchAgentId,
      interval: this.internalModel.interval,
      startDate: this.internalModel.startDate,
      endDate: this.internalModel.endDate,
      searchName: this.internalModel.searchName,
      eventCompetencesDTO: {
        baseCompetences: getCompetenceValueAsStringArray(this.internalModel.eventCompetencesDTO.baseCompetences as KeyValue[]),
        competences: getCompetenceValueAsStringArray(this.internalModel.eventCompetencesDTO.competences as KeyValue[]),
        topics: getCompetenceValueAsStringArray(this.internalModel.eventCompetencesDTO.topics as KeyValue[])
      }
    }
    this.saveEventAgent(saveModel).then((id) => {
      message = this.$t('info.save-success', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = false
      this.internalModel.searchAgentId = id
      this.$emit('saved', this.internalModel)
    }).catch(() => {
      message = this.$t('info.save-failed', { what: this.$tc('search-agent.search-agent', 1) }).toString()
      failed = true
    }).finally(() => {
      this.$root.$emit('alert', message, '', failed)
      this.isEditModeInternalModel = false
    })
  }
}
